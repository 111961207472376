/*! Agile Pixel https://agilepixel.io - 2023*/

import { Loader } from '@googlemaps/js-api-loader';

const mapID = document.querySelector('.js-map--simple');

if (mapID) {
  const loader = new Loader({
    apiKey: 'AIzaSyCDH2Xm8Bf13qKmdVtZQUrL2PLtf-Z1UuM',
    version: 'weekly',
    libraries: ['places'],
  });

  // Promise
  loader
    .load()
    .then((google) => {
      let { zoom, lat, lng } = mapID.dataset;

      if (lat && lng) {
        lat = parseFloat(lat);
        lng = parseFloat(lng);
        zoom = parseFloat(zoom);
        const location = { lat, lng };

        const map = new google.maps.Map(mapID, {
          center: location,
          zoom,
          minZoom: 8,
          maxZoom: 20,
          disableDefaultUI: true,
        });

        const markerLocation = { lat, lng };

        // The marker, positioned at Uluru
        const marker = new google.maps.Marker({
          position: markerLocation,
          animation: google.maps.Animation.DROP,
          map,
          icon: {
            url: `https://${window.location.hostname}/wp-content/themes/legion/resources/assets/images/pin.svg`,
            scaledSize: new google.maps.Size(50, 50),
          },
        });

        const toggleBounce = function () {
          if (marker.getAnimation() === undefined) {
            marker.setAnimation(google.maps.Animation.BOUNCE);
          } else {
            marker.setAnimation(undefined);
          }
        };

        marker.addListener('click', toggleBounce);
      }
    })
    .catch(() => {
      // do something
    });
}
