/*! Agile Pixel https://agilepixel.io - 2023*/

import { Loader } from '@googlemaps/js-api-loader';

const mapID = document.querySelector('.js-map--multi');
const locations = document.querySelectorAll('.js-travel__location__item');

if (mapID) {
  const loader = new Loader({
    apiKey: 'AIzaSyCDH2Xm8Bf13qKmdVtZQUrL2PLtf-Z1UuM',
    version: 'weekly',
    libraries: ['places'],
  });

  // Promise
  loader
    .load()
    .then((google) => {
      let { zoom, lat, lng } = locations[0].dataset;

      if (lat && lng) {
        lat = parseFloat(lat);
        lng = parseFloat(lng);
        zoom = parseFloat(zoom);
        const location = { lat, lng };

        const map = new google.maps.Map(mapID, {
          center: location,
          zoom,
          minZoom: 8,
          maxZoom: 20,
          disableDefaultUI: true,
          styles: [
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#193341',
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c5a71',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#29768a',
                },
                {
                  lightness: -37,
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#406d80',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#406d80',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#3e606f',
                },
                {
                  weight: 2,
                },
                {
                  gamma: 0.84,
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  weight: 0.6,
                },
                {
                  color: '#1a3541',
                },
              ],
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2c5a71',
                },
              ],
            },
          ],
        });

        //const markerLocation = { lat, lng };
        const markers = [];
        for (const location of locations) {
          const { destination } = location.dataset;
          let { lat, lng } = location.dataset;
          lat = parseFloat(lat);
          lng = parseFloat(lng);
          const markerLocation = { lat, lng };
          // The marker
          const marker = new google.maps.Marker({
            position: markerLocation,
            animation: google.maps.Animation.DROP,
            map,
            customData: destination,
            icon: {
              url: `https://${window.location.hostname}/wp-content/themes/legion/resources/assets/images/pin.svg`,
              scaledSize: new google.maps.Size(50, 50),
            },
          });

          markers.push(marker);

          marker.addListener(
            'click',
            () => {
              map.panTo(marker.getPosition());
              // Reset all other markers to pin.svg
              for (let index = 0; index < locations.length; index++) {
                markers[index].setIcon({
                  url: `https://${window.location.hostname}/wp-content/themes/legion/resources/assets/images/pin.svg`,
                  scaledSize: new google.maps.Size(50, 50),
                });
              }

              // Set the clicked marker to pin-active.svg
              marker.setIcon({
                url: `https://${window.location.hostname}/wp-content/themes/legion/resources/assets/images/pin-active.svg`,
                scaledSize: new google.maps.Size(50, 50),
              });
              for (const location of locations) {
                if (location.classList.contains('is-active')) {
                  location.classList.remove('is-active');
                }

                if (location.dataset.destination === marker.customData) {
                  location.classList.add('is-active');
                }
              }
            },
            marker,
          );
        }
      }

      /*       const toggleBounce = function () {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
        } else {
          marker.setAnimation(google.maps.Animation.BOUNCE);
        }
      }; */
    })
    .catch(() => {
      // do something
    });
}
