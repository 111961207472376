const input = document.querySelector('.js-filter');
if (input) {
  input.addEventListener('input', function () {
    const searchValue = input.value.toLowerCase();
    const items = document.querySelectorAll('.b-abstracts__item');

    for (const item of items) {
      const title = item.querySelector('.js-title').textContent.toLowerCase();
      const author = item.querySelector('.js-author').textContent.toLowerCase();

      if (
        (title && title.includes(searchValue)) ||
        (author && author.includes(searchValue))
      ) {
        item.classList.add('match');
        item.classList.remove('missmatch');
      } else {
        item.classList.add('missmatch');
        item.classList.remove('match');
      }
    }
  });
}
